<template>
  <div :class="className">
    <img :src="image">
    <span v-show="showsDescription">{{ description }}</span>
  </div>  
</template>

<script>
export default {
  props: [
    // The filename which is used to determine the extension and the related
    // description and icon
    'filename',

    // Set to true to force a "play" button that is used for videos
    'isVideo',

    // Can be 'regular' or 'small'
    'size'
  ],
  data() {
    return {
      description: null
    }
  },
  computed: {
    imageName: function() {
      if (this.isVideo) {
        return "grid-item-video-button.png"
      }

      return "grid-item-preview-file-general.png"
    },

    image: function() {
      return '../assets/images/' + this.imageName
    },

    showsDescription() {
      return this.filename != null
    },

    className: function() {
      if (this.size == "small") {
        return "file-thumbnail small"
      }

      return "file-thumbnail"
    },
  },
  methods: {
    reloadDescription() {
      if (!this.showsDescription) {
        return
      }

      if (this.isVideo) {
        return
      }

      const extension = this.filename.split('.').pop().toUpperCase();
      if (!extension) {
        this.description = "Unknown Type"
      }

      if (this.$root.fileMappingLoaded == true) {
        const formattedExtension = "." + extension
        const fileDescription = this.$root.fileMapping[formattedExtension]
        if (fileDescription) {
          this.description = fileDescription["description"]
        } else {
          this.description = "Unknown Type"
        }
      } else {
        // Retry in a second
        var self = this
        setTimeout(() => { self.reloadDescription() }, 500);
      }
    }
  },
  created: function() {
    this.reloadDescription()
  }  
}
</script>

<style lang="scss">
.file-thumbnail {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  display: block;
  text-align: center;

  &.small {
    img {
      width: 20px;
    }
  }

  span.error-title {
    font-size: 15px;
    font-weight: 700;
    color: #888;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 60px;
  }

  span {
    display: block;
    text-align: center;
    margin-top: 20px;

    font-weight: 700;
    font-size: 10px;
    color: #AAAAAA;
    letter-spacing: -0.59px;
    text-transform: uppercase;
  }
}
</style>
