<template>
  <div class="video-player">
    <div class="video-container card-shadow">
      <video controls ref="video">
        <source :src="src" type="video/mp4" />
        <p>Your browser does not support this video type. Use the download button below to download the video.</p>
      </video>
    </div>
  </div>
</template>

<script>
export default {
  props: ['src'],
  mounted: function() {
    // Force a layout call
    var self = this
    this.$refs.video.addEventListener('loadeddata', function() {
        // Reset the 100% width that we set while it's loading the video
        self.$refs.video.style.width = null;

        // Trigger a new layout event, this will set the correct width etc
        self.$nextTick(function () { document.dispatchEvent(new Event("itemDetailLoaded")) });
    }, false)
  }
}
</script>

<style lang="scss" scoped>
  .video-player {
    margin: 0;
    padding: 0;
    text-align: center;
    min-height: 150px;
    display: block;

    .video-container {
      display: inline-block;
      border-radius: 5px;
      overflow: hidden;

      video {
        margin: 0 auto;
        padding: 0;
        display: block;
        max-width: 100%;
        width: 90vw;
      }
    }

  }
</style>
