<template>
  <div class="grid-item">
    <SessionGridItemPreview 
      :item="item"
      :state="state"
      displayContext="grid"
      @select="$emit('select-item')"
      @retry="$emit('retry-loading-item')"
    />
    <div class="grid-item-footer">
      <div class="grid-item-footer-element metadata">
        <span class="name">{{ title }}</span>
        <span class="size"><ItemSize :size="item.content_length" /></span>
      </div>
      <div class="grid-item-footer-element actions">
        <button class="download" v-on:click="$emit('download-item')"></button>
      </div>
    </div>
  </div>
</template>

<script>
import ItemSize from './ItemSize.vue'
import SessionGridItemPreview from './SessionGridItemPreview.vue'

export default {
  name: 'SessionGridItem',
  props: ['item', 'state'],
  components: { ItemSize, SessionGridItemPreview },
  computed: {
    backgroundStyle: function() {
      return {
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundImage: "url(" + this.item["img_small_url"] + ")"
      }
    },

    title: function() {
      const title = this.item['file_name']
      const maxChars = 28

      if (title.length <= maxChars) return title;

      const separator = separator || '...';
      var sepLen = separator.length, charsToShow = maxChars - sepLen, frontChars = Math.ceil(charsToShow/2), backChars = Math.floor(charsToShow/2);

      return title.substr(0, frontChars) + separator +  title.substr(title .length - backChars);
    },

    canPreview: function() {
      return this.item["media_type"] == "image" || this.item["media_type"] == "video"
    },

    fileType: function() {
      return this.item["file_name"].split('.').pop();
    },

    isPending: function() {
      return this.state == "pending"
    },

    isLoaded: function() {
      return this.state == "loaded"
    },

    hasError: function() {
      return this.state == "error"
    },

    containerClass: function() {
      if (this.state == "pending" || this.state == "error") {
        return "item item-pending"
      }

      if (this.item["media_type"] == "image") {
        return "item item-image"
      }

      return "item item-other"
    }
  }
}
</script>

<style lang="scss" scoped>
  .grid-item-footer {
    margin-top: 24px;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 18px;
    grid-template-areas: "metadata actions";

    .grid-item-footer-element {
      padding: 0 8px;
      &.metadata {
        justify-self: start;

        span.name {
          font-weight: 700;
          font-size: 15px;
          color: #323D4E;
          letter-spacing: -0.31px;
          overflow-wrap: break-word;
          word-wrap: break-word;
          hyphens: auto
        }

        span.size {
          font-weight: 500;
          font-size: 13px;
          color: #9AA1AD;
          letter-spacing: -0.77px;
          overflow-wrap: break-word;
          word-wrap: break-word;
          hyphens: auto
        }
      }
      
      &.actions {
        justify-self: end;

        button.download {
          display: block;
          width: 26px;
          height: 26px;

          border: none;
          outline: none;
          background: none;
          background-image: url('/assets/images/grid-item-download.png');
          background-repeat: no-repeat;
          background-size: 26px;

          transition: all 0.3s;

          &:hover {
            opacity: 0.7;
            cursor: pointer;
          }
        }
      }
    }
  }

  @media (prefers-color-scheme: dark) {
    .grid-item-footer {
      .grid-item-footer-element {
        &.metadata {
          span.name {
            color: white;
          }
        }

        &.actions {
          button.download {
            background-image: url('/assets/images/grid-item-download-dark.png');
          }
        }
      }
    }
  }
</style>
