<template>
  <div class="listing-item alternating">
    <div class="listing-item-preview">
      <SessionGridItemPreview 
         :item="item" 
         :state="state" 
         displayContext="list" 
         @select="$emit('select-item')"
         @retry="$emit('retry-loading-item')"
         />
    </div>
  <div class="listing-item-body" v-on:click="handleClick">
        <span class="name">{{ title }}</span>
        <span class="size"><ItemSize :size="item.content_length" /></span>
    </div>
    <div class="listing-item-accessory">
      <button class="download" v-on:click="$emit('download-item')"></button>
    </div>
  </div>
</template>

<script>
import ItemSize from './ItemSize.vue'
import SessionGridItemPreview from './SessionGridItemPreview.vue'

export default {
  name: 'SessionListItem',
  props: [
    // The item that should be displayed
    'item', 

    // The state of the item
    'state'
  ],
  components: { ItemSize, SessionGridItemPreview },
  computed: {
    backgroundStyle: function() {
      return {
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundImage: "url(" + this.item["img_small_url"] + ")"
      }
    },

    title: function() {
      return this.item['file_name']
    },

    canPreview: function() {
      return this.item["media_type"] == "image" || this.item["media_type"] == "video"
    },

    fileType: function() {
      return this.item["file_name"].split('.').pop();
    },

    isPending: function() {
      return this.state == "pending"
    },

    isLoaded: function() {
      return this.state == "loaded"
    },

    hasError: function() {
      return this.state == "error"
    },

    containerClass: function() {
      if (this.state == "pending" || this.state == "error") {
        return "item item-pending"
      }

      if (this.item["media_type"] == "image") {
        return "item item-image"
      }

      return "item item-other"
    }
  },
  methods: {
    handleClick: function() {
      if (!this.canPreview) { // Only previewable files can make the list clickable
        return
      }

      this.$emit('select-item')
    }
  }
}
</script>

<style lang="scss" scoped>

  .listing-item {
    color: white;
    display: grid;
    grid-template-columns: 80px auto 20px; // preview height is always 100px for lists
    grid-gap: 24px;

    padding: 12px;
    border-radius: 6px;

    &.alternating {
      &:nth-child(2n+2) {
        background: rgba(0, 0, 0, 0.05);
      }

      &:hover {
        background: rgba(0, 0, 0, 0.15);
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 0.15);
    }
  }

  .listing-item-preview {
    align-self: center;
  }

  .listing-item-body {
    display: block;
    align-self: center;

    span.name {
      font-weight: 700;
      font-size: 17px;
      color: #323D4E;
      letter-spacing: -0.31px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto
    }

    span.size {
      font-weight: 500;
      font-size: 14px;
      color: #9AA1AD;
      letter-spacing: -0.77px;
    }
  }

  .listing-item-accessory {
    justify-self: end;
    align-self: center;

    button.download {
      display: block;
      width: 26px;
      height: 26px;

      border: none;
      outline: none;
      background: none;
      background-image: url('/assets/images/grid-item-download.png');
      background-repeat: no-repeat;
      background-size: 26px;

      transition: all 0.3s;
      margin-right: 12px;

      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }


  @media (prefers-color-scheme: dark) {
    .listing-item {
      .listing-item-body {
        span.name {
          color: white;
        }

        span.size {
          color: #666;
        }
      }

      &.alternating {
        &:nth-child(2n+2) {
          background: rgba(0, 0, 0, 0.15);
        }

        &:hover {
          background: rgba(0, 0, 0, 0.25);
        }
      }
    }

    .listing-item-accessory button.download {
      background-image: url('/assets/images/grid-item-download-dark.png');
    }
  }
</style>
