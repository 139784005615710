<template>
  <div>
    <SessionLoader v-if="isLoading" v-on:success="loadSession" />
    <SessionOverview v-else-if="session" :session="session" :headers="headers"  :slug="slug" />
  </div>
</template>

<script>
import SessionLoader from './components/SessionLoader.vue'
import SessionOverview from './components/SessionOverview.vue'

export default {
  name: 'App',
  data() {
    return {
      // Slug of the session.
      slug: null,

      // Shows loader if true.
      isLoading: false,

      // Shows session if present.
      session: null,
      
      // HTTP headers. Only present if session requires authentication/
      headers: {},
    }
  },
  components: {
    SessionLoader,
    SessionOverview
  },
  methods: {
    loadSession: function(session, slug, headers) {
      this.slug = slug
      this.headers = {}
      this.session = session
      this.isLoading = false
    }
  },
  created: function() {
    this.isLoading = true
    this.session = null

    document.title = "Dropover Upload"
  },
  mounted: function() {
    var self = this
    fetch("assets/extension-description.json")
      .then(r => { return r.json() })
      .then(json => { 
        self.$root.fileMapping = json 
        self.$root.fileMappingLoaded = true
      })
  }
}
</script>

<style lang="scss">
@import './assets/scss/application.scss';
</style>
