<template>
  <div>{{ formattedSize }}</div>
</template>

<script>
export default {
  props: [
    // The size in bytes
    'size'
  ],
  computed: {
    formattedSize: function() {
      if (this.size == 0) {
        return "--"
      }

      var i = Math.floor( Math.log(this.size) / Math.log(1024) );
      return ( this.size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];    
    }
  },
}
</script>

<style scoped>

</style>
