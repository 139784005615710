<template>
  <!-- error --> 
  <div v-if="error">
    <NavigationHeader 
      title="Dropover Upload" 
      subtitle=""
    />

    <StateView 
      v-if="error != null"
      :title="error"
      state="error" 
    />
  </div>

  <!-- authentication -->
  <SessionPasswordPrompt 
    v-else-if="requiresAuthentication" 
    :slug="slug" 
    @authenticated="finishedAuthenticating" 
  />
  
  <!-- Loading state -->
  <div v-else>
    <NavigationHeader 
        title="Dropover Upload" 
        subtitle=""
    />

    <StateView 
      title="Loading..."
      state="loading" 
    />
  </div>
</template>

<script>
import SessionPasswordPrompt from './SessionPasswordPrompt.vue'
import StateView from './StateView.vue'
import NavigationHeader from './NavigationHeader.vue'

export default {
  components: { 
    NavigationHeader,
    SessionPasswordPrompt,
    StateView
  },
  data() {
    return {
      loading: true,
      pendingUploadCompletion: false,
      slug: null,
      requiresAuthentication: false,
      error: null,
    }
  },
  methods: {
    isExpired: function(session) {
      if (!session['expiration']) {
        return false
      }

      var expiration = moment.unix(session['expiration']).utc()
      return expiration.isBefore(moment.now())
    },

    finishedAuthenticating(session, headers) {
      this.$emit('success', session, this.slug, headers)
    },

    fetchSession: function(slug) {
      fetch("https://a.dropoverapp.com/cloud/links/" + slug)
      .then(r => {
        if (r.status == 403) {
          // Required authentication
          this.loading = false
          this.pendingUploadCompletion = false
          this.requiresAuthentication = true
          return null;
        } else if (r.status == 202) {
          // Upload in progress, retry
          this.error = "Waiting for upload to finish..."
          this.pendingUploadCompletion = true
          let self = this
          setTimeout(function() { self.fetchSession(self.slug) }, 5000);
          return null;
        } else {
          // Upload finished
          this.requiresAuthentication = false
          this.pendingUploadCompletion = false
          this.loading = false
          return r.json()
        }
      })
      .then(json => {
        if (!this.requiresAuthentication) {
          if (this.isExpired(json)) {
            this.error = "The item you are looking for is not here."
          } else if (json['status'] != null) {
            this.error = "The item you are looking for is not here."  
          } else {
            this.$emit('success', json, this.slug, {})
          }
        }

        this.loading = false
      })
      .catch(error => {
        if (this.pendingUploadCompletion) {
          this.error = "Waiting for upload to finish..."
        } else {
          this.error = "The item you are looking for is not here."
        }

        this.loading = false
      })
    },
  },
  mounted: function() {
    var slug = window.location.pathname.split("/").pop()
    if (!slug) {
      window.location.href = "https://dropoverapp.com/#dropover-cloud"
      return
    }

    this.slug = slug
    this.fetchSession(slug)
  }
}
</script>

<style scoped>

</style>
