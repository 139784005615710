<template>
  <div :class="containerClassName">

    <!-- Badge: Note: File extension that are longer than 7 chars will not be displayed -->
    <div v-show="badgeTitle != null" :class="badgeClassName">
      <span>{{ badgeTitle }}</span>
    </div>

    <!-- Pending state -->
    <div v-if="state == 'pending'" class="grid-item-preview-loading">
      <div v-if="displayContext == 'grid'">
        <span class="pulse">Generating preview...</span>
      </div>

      <div v-else>
        <span class="pulse">•••</span>
      </div>
    </div>

    <!-- Error state -->
    <div v-else-if="state == 'error'" class="grid-item-preview-error">
      <div v-if="displayContext == 'grid'">
        <span class="error-title">Failed generating preview</span>
        <button class="error-retry" v-on:click="$emit('retry')">Try again</button>
      </div>
      <div v-else>

      </div>
    </div>

    <!-- Image -->
    <div v-else-if="isImage" class="grid-item-preview-image" :style="imageBackgroundStyle" v-on:click="$emit('select')">
    </div>

    <!-- Video -->
    <div v-else-if="isVideo" class="grid-item-preview-video" v-on:click="$emit('select')">
      <ItemFileThumbnail :isVideo="true" :size="thumbnailSize" />
    </div>

    <!-- Non-image -->
    <div v-else class="grid-item-preview-file">
      <ItemFileThumbnail :filename="filename" :size="thumbnailSize" />
    </div>
  </div>
</template>

<script>
import ItemFileThumbnail from './ItemFileThumbnail.vue'

export default {
  props: [
    // The item to display
    'item', 

    // The state of the item
    'state', 

    // The context in which the item is displayed ('list' or 'grid')
    'displayContext'
  ],
  components: { ItemFileThumbnail },
  computed: {
    isImage: function() {
      return this.item['media_type'] == "image"
    },

    isVideo: function() {
      return this.item['media_type'] == "video"
    },

    filename: function() {
      if (this.displayContext == "list") {
        return null
      }

      return this.item['file_name']
    },

    thumbnailSize: function() {
      if (this.displayContext == "list") {
        return "small"
      }

      return "regular"
    },

    imageBackgroundStyle: function() {
      const borderRadius = this.displayContext == 'list' ? '6px' : '8px';
      return {
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundImage: "url(" + this.item["img_small_url"] + ")",
        borderRadius: borderRadius
      }
    },

    containerClassName: function() {
      if (this.displayContext == 'list') {
        return 'listing-item-preview'
      } else {
        return 'grid-item-preview'
      }
    },

    badgeTitle: function() {
      if (this.displayContext == 'list') {
        return null
      }

      const filenameExtension = this.item["file_name"].split('.').pop();
      if (filenameExtension.length > 7) {
        return null
      }

      return filenameExtension
    },

    badgeClassName: function() {
      if (this.state == 'pending' || this.state == 'error' || !this.isImage) {
        return 'grid-item-badge file'
      }

      return 'grid-item-badge image'
    } 
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/scss/_vars.scss';

  .grid-item-preview {
    position: relative;
    height: 235px;
    border-radius: 8px;

    background: #eee;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.07), 0 4px 10px 0 rgba(0,0,0,0.08);
  }

  .listing-item-preview {
    position: relative;
    height: 64px;
    border-radius: 6px;

    background: #eee;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.07), 0 4px 10px 0 rgba(0,0,0,0.08);
  }

  .grid-item-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 4px;
    
    &.image {
      background-color: rgba(0, 0, 0, 0.23);
      backdrop-filter: blur(10px);
    }

    &.file {
      background-color: #B5B5B5;
    }

    span {
      display: block;
      padding: 3px 6px;

      font-size: 9px;
      font-weight: 700;
      color: #FFFFFF;
      letter-spacing: -0.59px;
      text-transform: uppercase;
    }

  }

  // Error state
  .grid-item-preview-error {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    display: block;
    text-align: center;

    span.error-title {
      font-size: 15px;
      font-weight: 700;
      color: #888;
    }

    button.error-retry {
      display: block;
      outline: none;
      border: none;

      padding: 8px 12px;
      margin: 15px auto 0 auto;
      text-align: center;
      border-radius: 4px;

      color: $tint-color;
      font-size: 13px;
      font-weight: 700;

      transition: all 0.2s;

      &:hover {
        cursor: pointer;
        color: lighten($tint-color, 20%)
      }
    }
  }

  // Preview
  .grid-item-preview-loading {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    display: block;
    text-align: center;

    span {
      font-size: 15px;
      font-weight: 700;
      color: #888;
    } 
  }

  .listing-item-preview .grid-item-preview-loading span {
     font-size: 13px;
  }

  // Image preview
  .grid-item-preview-image {
    display: block;
    height: 100%;
    overflow: hidden;
    transition: all 0.2s;

    &:hover {
      cursor: zoom-in;
      transform: scale(1.02);
    }
  }

  .grid-item-preview-video {
    display: block;
    height: 100%;
    overflow: hidden;
    transition: all 0.2s;

    &:hover {
      cursor: zoom-in;
      transform: scale(1.02);
    }
  }

  .grid-item-preview-file {
    display: block;

    // Needed to vertically align ItemFileThumbnail
    height: 100%;
    position: relative;
  }

  @media (prefers-color-scheme: dark) {
    .grid-item-preview, .listing-item-preview {
      background: #404040;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.07), 0 4px 10px 0 rgba(0,0,0,0.08);
    }

    .grid-item-badge.file {
      background-color: #1B1B1B;
    }

    .grid-item-preview-error {
      button.error-retry {
        background: none;
        color: $tint-color-dark;

        &:hover {
          color: lighten($tint-color-dark, 15%);
        }
      }
    }
  }
</style>
