<template>
  <div class="item-detail-container">
    <button class="item-preview-close" v-on:click="$emit('close')"></button>
    <button class="item-preview-overflow" v-on:click="toggleQuickActions"></button>

    <div v-if="showsQuickActions" class="item-quick-actions-background" v-on:click="toggleQuickActions"></div>
    <div v-if="showsQuickActions" class="item-quick-actions card-shadow-light">
      <ul>
        <li v-on:click="downloadItem($event)">{{ quickLinksDownloadTitle }}</li>
        <li v-on:click="copyLink">Copy Link</li>
      </ul>
      <div v-if="" ref="clipboard"></div>
    </div>

    <div class="item-preview-container">
      <!-- loading state -->
      <div v-if="loading" class="content-container item-preview-loading">
        <div class="empty-state-container pulse">Loading...</div>
      </div>

      <!-- navigation -->
      <div v-show="!loading" class="item-preview-navigation">
        <button v-if="canGoBack" v-on:click="$emit('goBack')" href="#" class="control-previous"></button>
        <button v-if="canGoForward" v-on:click="$emit('goForward')" href="#" class="control-next"></button>
      </div>

      <!-- content -->
      <div class="item-preview-content">
        <img v-if="!loading && isImage" class="item-preview-image card-shadow" v-bind:src="image" />
        <VideoPlayer v-if="!loading && isVideo" :src="item.url"/>
      </div>
      
      <!-- footer -->
      <div v-if="!loading" class="item-preview-footer">
        <div class="item-preview-footer-body">
          <span>{{ item.file_name }}</span>
          <span><ItemSize :size="item.content_length" /></span>
        </div>
        
        <div class="item-preview-footer-actions">
          <button v-on:click="downloadItem($event)">Download</button>
        </div>
      </div>

    </div>
  </div>  
</template>

<script>
import ItemSize from './ItemSize.vue'
import VideoPlayer from './VideoPlayer.vue'

export default {
  props: {
    item: {
      type: Object
    },
    canGoBack: {
      type: Boolean,
      default: false
    },
    canGoForward: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isImage: function() {
      return this.item["media_type"] == "image"
    },

    isVideo: function() {
      return this.item["media_type"] == "video"
    },

    quickLinksDownloadTitle: function() {
      if (this.isImage) {
        return "Download Image"
      } else { 
        return "Download Video"
      }
    }
   },
  data() {
    return {
      showsQuickActions: false,
      image: null,
      loading: true
    }
  },
  components: { ItemSize, VideoPlayer },
  methods: {
    downloadItem: function(event) {
      event.preventDefault()

      window.location.href = this.item["url"];

      this.showsQuickActions = false
    },
    copyLink: function(event) {
      event.preventDefault()

      var clipboard = document.createElement("input");
      clipboard.type = "text";
      clipboard.value = window.location.href;
      document.body.appendChild(clipboard);

      clipboard.select();
      document.execCommand("copy");

      document.body.removeChild(clipboard);

      this.showsQuickActions = false
    },
    startLoadingImage: function() {
      this.currentItemIdentifier = this.item["id"]

      if (this.isVideo) {
        this.image = null
        this.loading = false
        return
      }

      this.loading = true
      var newImage = new Image;
      var self = this
      newImage.onload = function() {
        self.loading = false
        self.image = self.item["img_large_url"]
  
        self.$nextTick(function () { 
          document.dispatchEvent(new Event("itemDetailLoaded")) 
        });
      }
  
      newImage.src = this.item["img_large_url"]
    },
    toggleQuickActions: function() {
      this.showsQuickActions = !this.showsQuickActions
    }
  },
  component: {
    image: function() {
      if (this.loading) {
        return null
      }

      return this.item["img_large_url"]
    }
  },
  mounted: function() {
    this.startLoadingImage()
  },
  beforeUpdate: function() {
    if (this.currentItemIdentifier != null && this.item["id"] != this.currentItemIdentifier) {
      this.image = null
      this.startLoadingImage()
    }
  }
}
</script>

<style lang="scss" scoped>
  .item-detail-container {
    background-color: white;
    display: block;
    height: 100vh;
    width: 100%;

    @media (prefers-color-scheme: dark) {
      background: none;
    }
  }
</style>
