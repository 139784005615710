import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

window.constants = {
	// Interval for reloading sessions to determine pending items
	pendingItemsRetryInterval: 4000,

	// After this nr. of attempts, the item will display an message that it could
	// not be loaded
	pendingItemsMaxRetryCount: 3
}

new Vue({
	render: h => h(App),
	constants: constants,
	data: {
		fileMapping: {},
		fileMappingLoaded: false
	}
}).$mount('#app')

function layout() {
	layoutFooterIfNeeded()

	const itemImage = document.querySelector('.item-preview-image')
	const itemVideo = document.querySelector('.video-container')
	const itemFooter = document.querySelector('.item-preview-footer')
	if ((!itemImage && !itemVideo) || !itemFooter) {
		return;
	}

	itemFooter.style.width = (itemVideo || itemImage).offsetWidth + 'px'
	window.scrollTo(0,0);
	
	const navigationButton = document.querySelector('.item-preview-navigation button')
	if (!navigationButton) {
		return
	}
	
	const newTop = ((itemImage || itemVideo).offsetHeight / 2) - (navigationButton.offsetHeight / 2)	
	document.querySelectorAll('.item-preview-navigation button')
	.forEach(b => b.style.top = newTop + "px")
}

function layoutFooterIfNeeded() {
	const footers = document.getElementsByClassName('footer-container')
	if (!footers || footers.length == 0) {
		return
	}

	const footer = footers[0]
	const footerHeight = footer.offsetHeight	
	const contentHeight = document.getElementsByClassName('body-container')[0].offsetHeight
	const viewportHeight = window.innerHeight
	const difference = contentHeight - (viewportHeight-footerHeight)

	if (contentHeight >= viewportHeight-footerHeight) {
		footer.style.position = 'relative'
	} else {
		footer.style.position = 'fixed'
		footer.style.bottom = '0px'
	}
}

window.onresize = layout;
window.onload = layout;
document.addEventListener("itemDetailLoaded", layout);
document.addEventListener("performLayout", layout);
