<template>
<section>
  <div class="content-container">
    <div :class="containerClass">
      {{ title }}
    </div>
  </div>
</section>
</template>

<script>
export default {
  props: ['title', 'state'],
  computed: {
    containerClass: function() {
      if (this.state == "loading") {
        return "empty-state-container pulse"
      }

      return "empty-state-container"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>