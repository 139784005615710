<template>
  <div class="display-mode-selector">
    <button 
       class="grid"
       :class="{ 'selected': (selectedIndex == 0 || selectedIndex == null) }"
       v-on:click="$emit('didChangeSelectedIndex', '0')">
    </button>

    <button 
       class="list" 
       v-bind:class="{ 'selected': selectedIndex == 1 }" 
       v-on:click="$emit('didChangeSelectedIndex', '1')">
    </button>
  </div>  
</template>

<script>
export default {
  props: [
    'selectedIndex' // 0 = grid, 1 = list
  ]
}
</script>

<style lang="scss">
.display-mode-selector {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 4px;

  button {
    border: none;
    background: none;
    width: 34px;
    height: 30px;
    border-radius: 6px;
    outline: none;
    -webkit-appearance: none;
    transition: all 0.2s;

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.08);
    }

    &.grid {
      background-image: url('/assets/images/displaymode-grid-light.png');
      background-size: 20px 14px;
      background-position: center;
      background-repeat: no-repeat;
    }

    &.list {
      background-image: url('/assets/images/displaymode-list-light.png');
      background-size: 15px 11px;
      background-position: center;
      background-repeat: no-repeat;
      border: none;
      box-shadow: none;
      outline: none;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

@media (prefers-color-scheme: dark) {
  .display-mode-selector button {
    &.selected {
      background-color: rgba(255, 255, 255, 0.15);
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    &.grid {
      background-image: url('/assets/images/displaymode-grid-dark.png');
    }

    &.list {
      background-image: url('/assets/images/displaymode-list-dark.png');
    }
  }
}
</style>
