<template>
  <div class="prompt-outer-container">
    <div class="prompt-container">
      <div class="prompt-container-badge password"></div>
      <div class="prompt">
        <div class="prompt-header">
          <h1>Enter Password</h1>
          <p class="error" v-if="message">{{ message }}</p>
            <p v-else>This upload is password protected by the uploader. Enter the password below to proceed.</p>
        </div>

        <div class="prompt-body">
          <div class="form-container">
            <div class="form-container-element">
              <label for="p-input">Password</label>
              <input v-model="password" id="p-input" type="password" placeholder="Password" v-on:keyup.enter="requestSession"/>  
            </div>

            <div class="form-container-element">
              <button id="p-submit" v-on:click="requestSession" v-bind:disabled="isButtonDisabled">Submit</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['slug'],
  data () {
    return {
      password: null,
      message: null,
      requestHeaders: null,
      loading: false,
    }
  },
  computed: {
    headers: function() {
      if (this.password != null) {
        return { "X-DROPOVER-SESSION-P": btoa(this.password) }
      }

      return {}
    },

    isButtonDisabled: function() {
      return this.password === null || this.password.length < 3
    }
  },
  methods: {
    requestSession: function() {
      const requestOptions = {
        method: 'GET',
        headers: this.headers
      };
      fetch("https://a.dropoverapp.com/cloud/links/" + this.slug, requestOptions)
      .then(r => {
        if (r.status == 403) {
          this.passwordPrompt = true
          this.loading = false

          if (this.password != null) {
            this.message = "Entered password is invalid."
            this.password = ""
          }

          return null;
        } else {
          return r.json()
        }
      })
      .then(json => {
        if (json['id']) {
          this.$emit('authenticated', json, this.headers)
        }
        
        this.loading = false
      })
      .catch(error => {
        this.loading = false  
      })
    }
  }  
}
</script>

<style lang="scss" scoped>
  
</style>