<template>
  <!-- Error or empty state -->
  <StateView 
    v-if="error != null"
    :title="error"
    state="error" 
  />

  <!-- Item detail -->
  <ItemDetail 
    v-else-if="selectedItem" 
    :item="selectedItem" 
    :canGoBack="canNavigateToPreviousItem"
    :canGoForward="canNavigateToNextItem"
    @goBack="selectPreviousItem"
    @goForward="selectNextItem"
    @close="closeItem()" 
  />

  <!-- Issue reporter -->
  <IssueReporter 
   v-else-if="showsIssueReporter"
    :sessionId="currentSession.id"
    @close="showsIssueReporter = false"
  />

  <!-- Session contents -->
  <div v-else class="body-container">
    <NavigationHeader 
      :title="title" 
      :subtitle="formattedExpirationDate" 
      showsOverflowButton="true"
      @overflowButtonSelected="showsQuickActions  = !showsQuickActions"
    />

    <div v-if="showsQuickActions" class="item-quick-actions-background" v-on:click="showsQuickActions  = !showsQuickActions"></div>
    <div v-if="showsQuickActions" class="item-quick-actions card-shadow-light">
      <ul>
        <li v-on:click="copyLink">Copy Link</li>
        <li v-on:click="showsIssueReporter = true">Report Issue</li>
      </ul>
      <div v-if="" ref="clipboard"></div>
    </div>

    <!-- Listing -->
    <div v-if="session.link_type == 'page'">

      <!-- List -->
      <div v-if="displayMode == 1">
        <div class="container">
          <div class="list-container">
            <div class="content-container-header inset">
              <div>{{ subtitle }}</div>
              <DisplayModeSelector :selectedIndex="displayMode" @didChangeSelectedIndex="changeDisplayMode"/>
            </div>

            <SessionListItem 
               v-for="(item, index) in currentSession.items" 
               :item="item" 
               :state="itemState(item)"
               :alternate="currentSession.items.length > 2"
               @download-item="downloadItem(item)" 
               @select-item="selectItem(item)" 
               @retry-loading-item="retryReloadingPendingItems"
               />
          </div>
        </div>
      </div>

      <!-- Grid -->
      <div v-else>
        <div class="container">
          <div class="content-container-header">
              <div>{{ subtitle }}</div>
              <DisplayModeSelector :selectedIndex="displayMode" @didChangeSelectedIndex="changeDisplayMode"/>
          </div>
          <div class="grid-container">
            <SessionGridItem 
               v-for="(item, index) in currentSession.items" 
               :item="item" 
               :state="itemState(item)"
               @download-item="downloadItem(item)" 
               @select-item="selectItem(item)" 
               @retry-loading-item="retryReloadingPendingItems"
               />
          </div>
        </div>
      </div>


    </div>

    <!-- Direct download -->
    <div v-if="session.link_type == 'download'">
      <section>
        <div class="empty-state-container">
          Your file will begin downloading automatically. Don't see it? Click <a v-on:click="downloadItem(currentSession['items'][0])">here</a>.
        </div>
      </section>
    </div>

    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import DisplayModeSelector from './DisplayModeSelector.vue'
import SessionGridItem from './SessionGridItem.vue'
import SessionListItem from './SessionListItem.vue'
import ItemDetail from './ItemDetail.vue'
import StateView from './StateView.vue'
import IssueReporter from './IssueReporter.vue'
import NavigationHeader from './NavigationHeader.vue'
import Footer from './Footer.vue'

export default {
  components: { 
    DisplayModeSelector,
    SessionGridItem,
    SessionListItem,
    ItemDetail,
    StateView,
    IssueReporter,
    NavigationHeader,
    Footer
  },
  props: ['session', 'slug', 'headers'],
  data() {
    return {
      currentSession: null,
      selectedItem: null,
      previewableItems: [],
      pendingItems: [],
      pendingItemsRetryCount: 0,
      error: null,
      showsIssueReporter: false,
      showsQuickActions: false,
      displayMode: null // 0 = grid, 1 = list
    }
  },
  computed: {
    title: function() {
      if (this.currentSession["title"]) {
        return this.currentSession["title"]
      } 
      
      if (this.currentSession["items"].length == 1) {
        return this.currentSession["items"][0]["file_name"]
      }
      
      return "Dropover Upload"
    },

    subtitle: function() {
      return this.itemsTitle
    },

    itemsTitle: function() {
      if (this.currentSession["items"].length > 1) {
        return this.currentSession["items"].length + " items";
      }

      return "1 item";
    },

    formattedExpirationDate: function() {
      if (!this.currentSession['expiration']) {
        return null
      }

      return "Expires " + moment.unix(this.currentSession['expiration']).utc().fromNow()
    },

    canNavigateToPreviousItem: function() {
      if (this.previewableItems.length < 2) {
        return false;
      }

      const currentIndex = this.previewableItems.findIndex((element) => element == this.selectedItem["id"])
      return currentIndex >= 1
    },

    canNavigateToNextItem: function() {
      if (this.previewableItems.length < 2) {
        return false;
      }
      
      const currentIndex = this.previewableItems.findIndex((element) => element == this.selectedItem["id"])
      if (currentIndex == -1 || currentIndex == this.previewableItems.length - 1) {
        return false
      }

      return true
    },
    
    previousItem: function() {
      if (!this.selectedItem) {
        return null
      }

      const totalItems = this.previewableItems.length
      if (totalItems < 2) {
        return null
      }
      
      const currentIndex = this.previewableItems.findIndex((element) => element == this.selectedItem["id"])
      if (currentIndex == -1, currentIndex == 0) {
        return null
      }
      
      return this.previewableItems[currentIndex - 1]
    },
    
    nextItem: function() {
      if (!this.selectedItem) {
        return null
      }

      const totalItems = this.previewableItems.length
      if (totalItems < 2) {
        return null
      }
      
      const currentIndex = this.previewableItems.findIndex((element) => element == this.selectedItem["id"])
      if (currentIndex == -1 || currentIndex == totalItems - 1) {
        return null
      }
      
      return this.previewableItems[currentIndex + 1]
    },

    // Returns true if session is expired (based on expiration date)
    hasExpired: function() {
      const expiration = this.currentSession['expiration']
      if (!expiration) {
        return false
      }

      const expirationDate = moment.unix(expiration).utc()
      return expirationDate.isBefore(moment.now())
    },
  },
  methods: {
    itemState: function(item) {
      return "loaded"
    },

    downloadItem: function(item) {
      window.location.href = item["url"];
    },

    selectItem: function(item) {
      this.selectedItem = item
    },

    selectNextItem: function(event) {
      const newItemIndex = this.currentSession["items"].findIndex((e) => e["id"] == this.nextItem)
      this.selectedItem = this.currentSession["items"][newItemIndex]
    },
    
    selectPreviousItem: function(event) {
      const newItemIndex = this.currentSession["items"].findIndex((e) => e["id"] == this.previousItem)
      this.selectedItem = this.currentSession["items"][newItemIndex]
    },

    closeItem: function() {
      this.selectedItem = null
    },

    copyLink: function() {
      event.preventDefault()

      var clipboard = document.createElement("input");
      clipboard.type = "text";
      clipboard.value = window.location.href;
      document.body.appendChild(clipboard);

      clipboard.select();
      document.execCommand("copy");

      document.body.removeChild(clipboard);

      this.showsQuickActions = false
    },

    handleKeyboardInput: function(event) {
      // Next item
      if (event.keyCode == 39 && this.nextItem != null) {
        event.preventDefault()
        event.stopPropagation()
        this.selectNextItem()
      }
      
      // Previous item
      if (event.keyCode == 37 && this.previousItem != null) {
        event.preventDefault()
        event.stopPropagation()
        this.selectPreviousItem()
      }
      
      // Close selected item
      if (event.keyCode == 27 && this.selectedItem != null) {
        event.preventDefault()
        event.stopPropagation()
        this.selectedItem = null;
      }

      // Close issue reporter
      if (event.keyCode == 27 && this.showsIssueReporter != null) {
        event.preventDefault()
        event.stopPropagation()
      }
    },

    itemState: function(item) {
      if (this.pendingItems.includes(item["id"])) {
        // Did we reach max reload attempts?
        if (this.pendingItemsRetryCount >= this.$root.$options.constants.pendingItemsMaxRetryCount) {
          return "error"
        } else {
          return "pending"
        }
      }

      return "loaded"
    },

    reloadPendingItems: function() {
      this.pendingItemsRetryCount++

      const requestOptions = {
        method: 'GET',
        headers: this.headers
      }
      
      fetch("https://a.dropoverapp.com/cloud/links/" + this.slug, requestOptions)
      .then(r => {
        return r.json()
      })
      .then(json => {
        var pendingItems = []

        for (let i = 0; i < json["items"].length; i++) { 
          const item = json["items"][i]
          const supplementaryUrls = item["supplementary_urls"]
          if (supplementaryUrls != null && (item["img_small_url"] == null || item["img_large_url"] == null)) {
            pendingItems.push(item["id"])
          }
        }
        
        this.pendingItems = pendingItems
        this.currentSession = json
        
        if (this.pendingItems.length > 0) {
          if (this.pendingItemsRetryCount >= this.$root.$options.constants.pendingItemsMaxRetryCount) {
            return
          } else {
            setTimeout(this.reloadPendingItems, this.$root.$options.constants.pendingItemsRetryInterval);
          }
        }
      })
    },

    changeDisplayMode: function(newDisplayMode) {
      this.displayMode = newDisplayMode
    },

    retryReloadingPendingItems: function() {
      this.pendingItemsRetryCount = 0
      this.reloadPendingItems()
    },
  },
  watch: {
    selectedItem: function(val) {
      if (this.selectedItem) {
        var pageTitle = this.selectedItem["file_name"] + " - " + this.title;
        document.title = pageTitle

        history.pushState(this.selectedItem["id"], pageTitle, "/" + this.slug + "#" + this.selectedItem["id"]);

        // Trigger layout
        this.$nextTick(function () {
          document.dispatchEvent(new Event("itemDetailLoaded"));
        });
      } else {
        history.pushState("", this.title, "/" + this.slug);
        document.title = this.title

        this.$nextTick(function () {
          // document.documentElement.scrollTop = this.scrollPositions['grid']
        });
      }
    },

    displayMode: function(newValue, oldValue) {
      if (newValue == oldValue) {
        return
      }

      localStorage.setItem('contentDisplayMode', newValue)
      this.displayMode = newValue
    },
  },
  updated: function() {
    // For sticky footer
    this.$nextTick(function () {
      document.dispatchEvent(new Event("performLayout"));
    });
  },
  created: function() {
    this.currentSession = this.session
    this.displayMode = localStorage.contentDisplayMode

    document.title = this.title

    if (this.currentSession['link_type'] == 'download') {
      var item = this.currentSession['items'][0]
      setTimeout(function() { window.location.href = item['url'] }, 1000);
    }

    // Schedule expiration if needed
    if (this.currentSession["expiration"] != null) {
      var self = this
      setInterval(function() {
        if (this.hasExpired) {
          self.error = "The link has expired."
        } 
      }, 5000);
    }

    // Select item if hash is included in URL
    const h = location.hash.replace("#", "");
    const previewableItems = []
    const pendingItems = []    
    for (let i = 0; i < this.currentSession["items"].length; i++) { 
      var item = this.currentSession["items"][i]

      // Select current item if needed
      if (h && item["id"] == h) {
       this.selectedItem = item
      }

      // Determine if item is pending
      const supplementaryUrls = item["supplementary_urls"]
      if (supplementaryUrls != null && (item["img_small_url"] == null || item["img_large_url"] == null)) {
        pendingItems.push(item["id"])
      } 
      
      // Collect previewable items: Only images which are not pending are considered previewable.
      if (item["media_type"] == "image" && !pendingItems.includes(item["id"])) {
        previewableItems.push(item["id"])
      }

      if (item["media_type"] == "video") {
        previewableItems.push(item["id"])
      }
    }

    // If we have pending items, request the session again in 3 seconds and update ui accordingly
    this.pendingItems = pendingItems
    if (pendingItems.length > 0) {
      setTimeout(this.reloadPendingItems, this.$root.$options.constants.pendingItemsRetryInterval);
    }

    if (previewableItems.length > 0) {
      this.previewableItems = previewableItems
    }

    document.addEventListener("keyup", this.handleKeyboardInput);
  },
  mounted: function() {
    // For sticky footer
    this.$nextTick(function () {
		  document.dispatchEvent(new Event("performLayout"));
	  });
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/scss/_vars.scss';
  @import '../assets/scss/media.scss';

  .content-container-header {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;

    margin-top: 100px;
    margin-bottom: 24px;

    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    color: #C0C0C0;
    letter-spacing: 1.93px;

    &.inset {
      padding-left: 12px;
    }

    @media (max-width: 900px) {
      margin-top: 30px;
    }
  }

  .content-container-header div:nth-child(2) {
    justify-self: end;
  }

  .grid-container {
    display: grid;
    column-gap: 32px;
    row-gap: 46px;

    @media (min-width: 320) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    @media (min-width: 500px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 16px;
    }

    @media (min-width: 600px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 32px;
    }

    @media (min-width: 660px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 46px;
    }

    @media (min-width: 780px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 66px;
    }

    @media (min-width: 900px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 16px;
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 32px;
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 66px;
    }
  }

  .list-container {
    max-width: 100%;
    margin: 40px auto 0 auto;

    @media (min-width: 900px) {
      width: 768px;
      margin-top: 15px;
    }

    @media (min-width: 1024px) {
      width: 768px;
      margin-top: 15px;
    }

    @media (min-width: 1200px) {
      width: 768px;
      margin-top: 15px;
    }
  }

</style>

